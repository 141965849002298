<template>
	<div class="category-list">
		<v-container class="text-center mb-8 mb-md-12">
			<h3>{{this.page.texts.HOME_MAKE_YOUR.NAME}}</h3>
			<div class="manual color-black">{{this.page.texts.HOME_DESCOVER.NAME}}</div>
		</v-container>
		<div class="swiper category-list__list" @mousemove="onMouseMove" @mouseout="onMouseOut">
			<div class="swiper-wrapper">
				<div class="swiper-slide text-center" v-for="(item, index) in items" :key="index">
					<router-link :to="{name:'category', params:{code:item.CODE}}"
								 class="pa-4 d-flex flex-column align-center category-list__image-link">
						<v-img :src="item.PICTURE" contain :alt="item.NAME"/>
					</router-link>
					<router-link :to="{name:'category', params:{code:item.CODE}}"
								 class="mt-6 mt-md-8 category-list__link">
						<h5>{{ item.NAME }}</h5>
						<!--<p class="size2 mt-2 mt-md-3" v-html="item.DESCRIPTION"></p>-->
					</router-link>

					<!--<btn-primary label="Shop now" @click="$router.push({name:'category', params:{code:item.CODE}})"/>-->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {Swiper} from 'swiper';
	import 'swiper/swiper.min.css';
	import vp from "@/mixins/vp";
	import BtnPrimary from "./btn-primary";
	import {debounce, throttle} from "@/utils/utils";

	let swiper;

	export default {
		name: "category-list",
		components: {BtnPrimary},
		mixins: [vp],
		props: ["items"],
		data() {
			return {
				speed: 0,
				interval: null
			}
		},
        computed: {
            page() {
                if (this.$store.state.page.CatalogController) return this.$store.state.page.CatalogController;
                return this.$store.state.page.HomeController;
            },
        },
		methods: {
			/*onMouseOverSlide(slide, slideIndex) {
				if ( !swiper ) return;
				swiper.slideTo(slideIndex);
			},*/
			moveSlider() {
				if ( !swiper ) return;
				swiper.setProgress(swiper.progress+this.speed);
			},
			onMouseOut(event) {
				clearInterval(this.interval);
				this.interval = null;
			},
			onMouseMove(event) {
				if (!this.isMD) {
					clearInterval(this.interval);
					this.interval = null;
					return;
				}
				if ( !swiper ) return;
				const width = swiper.el.offsetWidth;
				const clientX = event.clientX;
				/*console.log("WIDTH: ", width);
				console.log("clientX: ", clientX);
				console.log("swiper.isBeginning: ", swiper.isBeginning);
				console.log("swiper.isEnd: ", swiper.isEnd);*/
				const WING = .2;
				const SPEED = 3;
				if ( clientX >= width * (1-WING) && !swiper.isEnd) {
					const speed = SPEED * (clientX-width*(1-WING))/(width*WING);
					//console.log("NEXT", speed);
					this.speed = .001 * speed;
				}
				else if ( clientX <= width * WING && !swiper.isBeginning) {
					const speed = SPEED * (width*WING-clientX)/(width*WING);
					//console.log("PREV", speed);
					this.speed = -.001 * speed;
				}
				else {
					clearInterval(this.interval);
					this.interval = null;
					return;
				}

				if ( !this.interval ) this.interval = setInterval(this.moveSlider, 1);
			}
		},
		mounted() {
			swiper = new Swiper('.swiper.category-list__list', {
				freeMode: true,
				slidesPerView: 1.5,
				spaceBetween: 0,
				//mousewheel: true,
				//grabCursor: true,
				breakpoints: {
					360: {
						slidesPerView: 1.5,
						//spaceBetween: 5
					},
					400: {
						slidesPerView: 1.5,
						//spaceBetween: 5
					},
					520: {
						slidesPerView: 2,
						//spaceBetween: 20
					},
					680: {
						slidesPerView: 2.5,
						//spaceBetween: 20
					},
					840: {
						slidesPerView: 3,
						//spaceBetween: 20
					},
					/*1000: {
						slidesPerView: 3.5,
						//spaceBetween: 20
					},*/
					1160: {
						slidesPerView: 3.5,
						//spaceBetween: 20
					},
					1320: {
						slidesPerView: 4.0,
						//spaceBetween: 20
					},
					/*1480: {
						slidesPerView: 4,
						//spaceBetween: 20
					},*/
					1640: {
						freeMode: true,
						slidesPerView: 4.5,
						//spaceBetween: 20
					}/*,
					1800: {
						slidesPerView: 5.5,
						//spaceBetween: 20
					}*/
				}
			});
			//swiper.mousewheel.enable();
		}
	}
</script>

<style lang="scss">
	.category-list {

		&__list {
			//max-width: 1480px;	// todo for more categories

			@include up($md) {
				padding: 0 80px;
			}

			.swiper-slide {
				position: relative;
				height: 400px;

				@include up($md) {
					height: 450px;
				}

				/*
								.btn-primary {
									position: absolute;
									left: 50%;
									bottom: 0;
									transform: translateX(-50%);
								}*/
			}
		}

		&__image-link {
			// todo bolder bottom line

			@include up($md) {
				&:hover {
					opacity: unset;
					//transform: translateY(1px);
					filter: brightness(1.1) contrast(1.05);
				}
			}
		}

		&__link {
			display: inline-block;
			padding: 0 3px 2px 3px;
			border-bottom: 1px solid transparent;
			//opacity: 1 !important;
			color: $black !important;

			&:hover, &:active, &:focus {
				border-bottom: 1px solid $black-50;
				//opacity: 0.6;
				//text-decoration-color: $primary-500;
				//border-bottom: 1px solid $golden;
			}
		}

		.v-image {
			//display: block;
			//margin: 0;
			//width: 285px;
			height: 220px;

			@include up($lg) {
				//width: 330px;
				height: 280px;
			}

			/*@include up($lg) {
				width: 330px;
				height: 249px;
			}*/
		}

		h5 {
			//color: $golden;
			max-width: 215px;
		}

		p {
			color: $default-text-color;
			max-width: 260px;
		}
	}
</style>